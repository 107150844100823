$accent-color: #372865;
$background-color: #F6F9FE;
$border-color: #E3E3E3;
$font-color: #545454;
$font-color-accent: #CCC1E6;
$font-color-light: #B6B6B6;
$heading-color: #353252;
$white: #ffffff;
$secondary-accent-color: #803D85;

$bp-tablet: 760px;
$bp-desktop: 1024px;